import VueRouter from "vue-router";

/* ====== ROUTE SPLITTING & LAZY LOADING OPTIMIZATION!!! ====== */
/* https://vueschool.io/articles/vuejs-tutorials/vue-js-router-performance/ */

/* DO NOT IMPORT OUTSIDE ACCESSING ROUTE UNLESS VERY VERY GOOD REASON
SEEK BEST POSSIBLE OPTIMIZATION, ALWAYS, OR I WILL HAUNT YOU IN YOUR SLEEP. */

let routes = [
  {
    path: "/",
    component: () => import("./HomePage"),
    name: "HomePage",
  },

  {
    path: "/nutrition",
    component: () => import("./NutrinoHome"),
    name: "NutrinoHome",
  },

  {
    path: "/northstar",
    component: () => import("./NorthStar"),
    name: "NorthStar",
  },

  {
    path: "/manifesto",
    component: () => import("./Manifesto"),
    name: "Manifesto",
  },

  {
    path: "/inmemory",
    component: () => import("./InMemory"),
    name: "InMemory",
  },

  {
    path: "/quotes",
    component: () => import("./Quotes"),
    name: "QuotesPage",
  },

  {
    path: "/about",
    component: () => import("./AboutPage"),
    name: "AboutPage",
  },

  /*
  {
    path: "/errors/404",
    name: "Error404Page",
    component: () => import("./pages/ErrorPages/Error404Page"),
  }, */

  { path: "*", redirect: "/errors/404" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
