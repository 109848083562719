import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import router from "./router";

import { VueWindowSizePlugin } from "vue-window-size/option-api";

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueWindowSizePlugin);

function setBodyHeight() {
  document.body.style.height = window.innerHeight + "px";
}

window.addEventListener("resize", setBodyHeight);
window.addEventListener("load", setBodyHeight);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
