<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  font-family: "Avenir", sans-serif !important;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #f1f1f1;
  box-sizing: border-box !important;
}
body {
  /* overflow: hidden; */ /* prevent scrolling past browser window confines to keep experience controlled */
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  background-color: #111;
}
/* #app {
  overflow: scroll;
} */
/* Hide scrollbars for the entire document */
body::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
  height: 0px;
}
body {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

/* Hide scrollbars for all elements */
*::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
  height: 0px;
}
* {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

a {
  text-underline-offset: 5px;
  text-decoration-thickness: 0.5px;
}
a:hover {
  color: #ccc;
}

.content {
  max-width: 650px;
  margin: auto;
  padding-bottom: 30px;
  line-height: 22px;
}
.section {
  border-radius: 20px;
  padding: 30px;
}
.image {
  width: 100%;
  border-radius: 16px;
  margin: 30px 0px;
}
.image--greyed {
  width: 100%;
  border-radius: 16px;
  filter: grayscale(1);
  transition: 0.2s ease;
}
.image--greyed:hover {
  filter: grayscale(0.2);
}
.link {
  text-underline-offset: 6px;
  text-decoration-thickness: 1px;
}
.link:hover {
  color: #ccc;
}
.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
ul li {
  list-style: disc;
  margin: 5px 0;
  margin-left: 15px;
  padding-left: 5px;
}

@media screen and (max-width: 700px) {
  .image {
    width: calc(100% - 30px);
    margin: 30px 15px;
  }
}

*::selection {
  background-color: #f1f1f1;
  color: #111;
}
</style>
